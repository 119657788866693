var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:[
    _vm.getBadgeType(),
    'text-center',
    'white--text',
    'px-3',
    'py-1',
    'rounded',
    'mew-caption'
  ]},[_vm._v(_vm._s(_vm.badgeTitle))])}
var staticRenderFns = []

export { render, staticRenderFns }