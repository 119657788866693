var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mew-components--mew-tabs"},[(_vm.compact)?_c('div',[_c('v-chip-group',{attrs:{"mandatory":"","column":""},model:{value:(_vm.onTab),callback:function ($$v) {_vm.onTab=$$v},expression:"onTab"}},_vm._l((_vm.items),function(mobileItem,mobileItemKey){return _c('v-chip',{key:mobileItemKey,staticClass:"ma-1",attrs:{"active-class":"greenPrimary text--primary","outlined":""}},[_vm._v(" "+_vm._s(mobileItem['name'])+" ")])}),1),_vm._l((_vm.items),function(mobileItem,mobileItemKey){return [_c('div',{key:mobileItemKey},[(_vm.onTab == mobileItemKey)?_vm._t('tabItemContent' + (mobileItemKey + 1)):_vm._e()],2)]})],2):_vm._e(),(!_vm.compact)?_c('v-tabs',{class:_vm.tabClasses,attrs:{"background-color":_vm.background,"color":_vm.activeColor,"centered":_vm.isCentered,"slider-size":"3","hide-slider":_vm.isBlock || _vm.isVertical,"grow":_vm.isBlock || _vm.hasFullWidth,"vertical":_vm.isVertical,"show-arrows":_vm.showArrows,"align-with-title":!_vm.isBlock && !_vm.isCentered && !_vm.isVertical},model:{value:(_vm.onTab),callback:function ($$v) {_vm.onTab=$$v},expression:"onTab"}},[_vm._l((_vm.items),function(item,i){return _c('v-tab',{key:item + i,class:[
        _vm.isBlock
          ? 'mew-tab-block'
          : _vm.isVertical || _vm.isSmall
          ? 'mew-body font-weight-medium'
          : 'mew-heading-2',
        'capitalize'
      ],attrs:{"ripple":!_vm.isVertical}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('v-tabs-items',{model:{value:(_vm.onTab),callback:function ($$v) {_vm.onTab=$$v},expression:"onTab"}},_vm._l((_vm.items),function(item,i){return _c('v-tab-item',{key:item + i,attrs:{"reverse-transition":!_vm.isVertical ? 'slide-x-transition' : 'slide-y-transition'}},[_vm._t('tabItemContent' + (i + 1))],2)}),1)],2):_vm._e(),_vm._l((_vm.items),function(item,i){return _c('div',{key:item + i},[_c('v-slide-x-reverse-transition',{attrs:{"hide-on-leave":true,"mode":"out-in"}},[(_vm.onTab === i)?_vm._t('tabContent' + (i + 1)):_vm._e()],2)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }