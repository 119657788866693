import XDC from '../types/XDC';
export default {
  type: XDC,
  service: 'xdc-network-ws',
  url: 'wss://ws.xinfin.network/',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
