import { AURORA } from '../types';
export default {
  type: AURORA,
  service: 'aurora.dev-ws',
  url: 'wss://mainnet.aurora.dev',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
