import { KLAY } from '../types';
export default {
  type: KLAY,
  service: 'klatnapi.com-ws',
  url: 'wss://public-node-api.klaytnapi.com/v1/cypress/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
