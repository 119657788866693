var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{class:[
      'mew-table',
      _vm.hasSelect ? 'mew-select-table' : '',
      _vm.hasColor ? 'mew-super-primary-table' : ''
    ],attrs:{"items":_vm.indexedItems,"item-key":"id","headers":_vm.tableHeaders,"show-select":_vm.hasSelect,"hide-default-footer":_vm.indexedItems && _vm.indexedItems.length <= 10,"items-per-page":10,"loader-height":0,"loading":_vm.loading,"no-data-text":_vm.noDataText},on:{"item-selected":_vm.onSelect,"toggle-select-all":_vm.onSelectAll},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return _vm._l((3),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"py-1",attrs:{"width":"100%","min-width":"100%","type":"text","elevation":"0"}})})},proxy:true}:null,(!_vm.loading)?{key:"item.token",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('mew-token-container',{staticClass:"mr-2 flex-shrink-0",attrs:{"size":"small","img":item.tokenImg}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(item.token))])],1)]}}:null,(!_vm.loading)?{key:"item.toggle",fn:function(ref){
    var item = ref.item;
return [(item.toggle)?_c('v-switch',{attrs:{"disabled":item.toggle.disabled,"inset":"","color":item.toggle.color},on:{"click":function($event){return item.toggle.method(item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(item.toggle.label)?_c('span',{class:item.toggle.color +
              '--text font-weight-regular mew-body capitalize'},[_vm._v(_vm._s(item.toggle.label))]):_vm._e()]},proxy:true}],null,true),model:{value:(item.toggle.value),callback:function ($$v) {_vm.$set(item.toggle, "value", $$v)},expression:"item.toggle.value"}}):_vm._e()]}}:null,(!_vm.loading)?{key:"item.change",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"chart-container d-flex align-center"},[(item.change !== '')?_c('span',{class:[
            item.status === '+' ? 'primary--text' : 'error--text',
            'd-flex'
          ]},[_vm._v(" "+_vm._s(item.change + '%')+" "),(item.status === '+')?_c('v-icon',{staticClass:"primary--text"},[_vm._v(" mdi-arrow-up-thick ")]):_vm._e(),(item.status === '-')?_c('v-icon',{staticClass:"error--text"},[_vm._v(" mdi-arrow-down-thick ")]):_vm._e()],1):_vm._e()])]}}:null,(!_vm.loading)?{key:"item.balance",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column py-2"},_vm._l((item.balance),function(bal,idx){return _c('span',{key:idx,class:idx === 1 ? 'searchText--text' : ''},[_vm._v(" "+_vm._s(bal)+" ")])}),0)]}}:null,(!_vm.loading)?{key:"item.callToAction",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row py-3 justify-end"},_vm._l((item.callToAction),function(button,idx){return _c('mew-button',{key:idx,class:idx !== item.callToAction.length - 1 &&
            item.callToAction.length > 1
              ? 'mr-1'
              : '',attrs:{"title":button.title,"disabled":button.disabled,"btn-size":"small","btn-style":button.btnStyle,"color-theme":button.colorTheme},nativeOn:{"click":function($event){return button.method(item)}}})}),1)]}}:null,(!_vm.loading)?{key:"item.txHash",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:[!_vm.$vuetify.breakpoint.xs ? 'pr-3' : '']},[_c('v-tooltip',{attrs:{"eager":"","open-on-hover":"","content-class":"tooltip-inner","color":"titlePrimary--text","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('a',_vm._g({staticClass:"font-weight-medium mew-address d-flex full-width",attrs:{"href":_vm.explorerTx(item),"target":"_blank"}},on),[_c('mew-transform-hash',{attrs:{"hash":item.txHash}}),_c('v-icon',{staticClass:"arrow-top-right",attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-top-right ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.txHash))])])],1)]}}:null,(!_vm.loading)?{key:"item.address",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('mew-blockie',{staticClass:"mr-2 d-none d-sm-flex",attrs:{"address":item.resolvedAddr ? item.resolvedAddr : item.address,"width":"25px","height":"25px"}}),_c('v-tooltip',{attrs:{"eager":"","open-on-hover":"","content-class":"tooltip-inner","color":"titlePrimary--text","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"address-container font-weight-medium mew-address d-flex"},on),[(item.resolvedAddr)?_c('span',{staticClass:"mew-address truncate"},[_vm._v(" "+_vm._s(item.address)+" ")]):_vm._e(),(!item.resolvedAddr)?_c('mew-transform-hash',{attrs:{"hash":item.address}}):_vm._e(),_c('mew-copy',{staticClass:"ml-3",attrs:{"copy-value":item.address}}),(_vm.explorerAddr(item) !== '')?_c('a',{staticClass:"address-link",attrs:{"href":_vm.explorerAddr(item),"target":"_blank"}},[_c('v-icon',{staticClass:"call-made"},[_vm._v(" mdi-call-made ")])],1):_vm._e()],1)]}}],null,true)},[_c('span',{attrs:{"id":"mew-table-address"}},[_vm._v(_vm._s(item.address))])])],1)]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }