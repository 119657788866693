import { GNO } from '../types';
export default {
  type: GNO,
  service: 'gnosischain.com-ws',
  url: 'wss://rpc.gnosischain.com/wss',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
