import { MOONRIVER } from '../types';
export default {
  type: MOONRIVER,
  service: 'moonbeam.network-ws',
  url: 'wss://wss.api.moonriver.moonbeam.network/',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
