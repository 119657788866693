<template>
  <v-btn icon x-large>
    <img src="@/assets/images/icons/icon-menu.svg" height="40" />
  </v-btn>
</template>
<script>
export default {
  name: 'AppBtnMenu'
};
</script>
