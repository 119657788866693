import { FTM } from '../types';
export default {
  type: FTM,
  service: 'fantom.network-ws',
  url: 'wss://wsapi.fantom.network',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
