var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{class:[
    _vm.hasElevation ? 'module-box-shadow' : '',
    'full-width',
    'module-container pt-8'
  ],attrs:{"color":_vm.colorType,"height":_vm.hasFullHeight ? '100%' : 'auto'}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('div',{class:[
          'left-wrapper pl-8 mt-2 d-flex flex-row',
          _vm.hasBodyPadding
            ? 'justify-start align-end pt-6'
            : 'justify-center align-center',
          _vm.hasIndicator ? 'indicator pl-md-13' : ''
        ]},[(_vm.showIcon(_vm.icon))?_c('div',{staticClass:"left-icon mr-2"},[(_vm.iconAlign.toLowerCase() === _vm.iconAlignments.left)?_c('mew-token-container',{attrs:{"img":_vm.icon,"size":"large","alt":"left icon"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[(_vm.subtitle)?_c('span',{staticClass:"mew-heading-3 textPrimaryModule--text text-uppercase"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),(_vm.title)?_c('span',{class:['titlePrimary--text d-flex align-center', _vm.titleSize]},[_vm._v(_vm._s(_vm.title)+" "),(_vm.mewTooltipText)?_c('mew-tooltip',{staticClass:"mb-1 ml-1",attrs:{"text":_vm.mewTooltipText}}):_vm._e()],1):_vm._e(),(_vm.caption)?_c('span',{staticClass:"mew-body textSecondaryModule--text font-weight-bold"},[_vm._v(_vm._s(_vm.caption))]):_vm._e()]),(_vm.showIcon(_vm.icon))?_c('div',{staticClass:"right-icon"},[(_vm.iconAlign.toLowerCase() === _vm.iconAlignments.right)?_c('mew-token-container',{attrs:{"img":_vm.icon,"size":"large","alt":"right icon"}}):_vm._e()],1):_vm._e(),_vm._t("leftHeaderContainer")],2),_c('div',{staticClass:"right-wrapper pr-10 pt-2"},[_vm._t("rightHeaderContainer")],2)]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-container',{class:_vm.hasBodyPadding ? 'pa-6 pb-13 px-md-13 py-md-8' : 'pa-0',attrs:{"fluid":""}},[_vm._t("moduleBody")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }