var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{ref:"mewAddressSelect",staticClass:"address-select pa-0 rounded-lg",attrs:{"color":"primary","items":_vm.items,"label":_vm.label,"item-value":"address","item-text":"address","placeholder":_vm.placeholder,"disabled":_vm.disabled,"error-messages":_vm.errorMessages,"hint":_vm.hint || _vm.resolvedAddr || '',"persistent-hint":_vm.resolvedAddr.length > 0 || _vm.hint.length > 0,"rules":_vm.rules,"no-data-text":_vm.noDataText,"menu-props":{ value: _vm.dropdown, closeOnClick: true },"outlined":""},on:{"update:search-input":_vm.onChange,"blur":function($event){_vm.dropdown = false}},nativeOn:{"input":function($event){return _vm.onInputChange.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(!_vm.isValidAddress || !_vm.blockieHash)?_c('div',{staticClass:"blockie-placeholder mr-1 selectHover"}):_vm._e(),(_vm.isValidAddress)?_c('mew-blockie',{staticClass:"mr-1",attrs:{"address":_vm.blockieHash,"width":"25px","height":"25px"}}):_vm._e()]},proxy:true},{key:"append",fn:function(){return [_c('div',{staticClass:"icon-container d-flex align-center"},[_c('mew-copy',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCopy),expression:"showCopy"}],staticClass:"mr-3",attrs:{"tooltip":_vm.copyTooltip,"copy-value":_vm.addressValue.address || _vm.addressValue}}),(_vm.showSave)?_c('v-tooltip',{attrs:{"content-class":"tooltip-inner","color":"textMediumWhite","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({class:[
              'save-icon',
              _vm.enableSaveAddress
                ? 'basic--text'
                : 'disabled--text, no-pointer-events'
            ],on:{"click":_vm.saveAddress}},on),[_vm._v(" mdi-bookmark-outline ")])]}}],null,false,1124993283)},[_c('span',[_vm._v(_vm._s(_vm.saveTooltip))])]):_vm._e()],1),_c('div',{staticClass:"dropdown-icon-container d-flex align-center justify-center cursor-pointer full-height",on:{"click":_vm.toggle}},[_c('v-icon',{staticClass:"mew-heading-1 mx-5"},[_vm._v(" mdi-chevron-down ")])],1)]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:[
        'py-4 px-0 full-width d-flex align-center justify-space-between',
        _vm.$vuetify.breakpoint.smAndDown ? 'column-reverse align-baseline' : ''
      ],on:{"click":function($event){return _vm.selectAddress(item)}}},[_c('div',{staticClass:"d-flex align-center justify-space-between full-max-width"},[_c('mew-blockie',{staticClass:"mr-2",attrs:{"address":item.resolvedAddr ? item.resolvedAddr : item.address,"width":"25px","height":"25px"}}),(!item.resolvedAddr || item.resolvedAddr === '')?_c('mew-transform-hash',{staticClass:"pr-6",attrs:{"hash":item.address}}):_c('span',{staticClass:"mew-address"},[_vm._v(_vm._s(item.address))])],1),_c('div',{staticClass:"overline primary--text font-weight-medium ml-3"},[_vm._v(" "+_vm._s(item.nickname)+" ")])])]}}]),model:{value:(_vm.addressValue),callback:function ($$v) {_vm.addressValue=$$v},expression:"addressValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }